





























































import { SfButton, SfSearchBar } from "@storefront-ui/vue";
import {
  defineComponent,
  ref,
  watch,
  useRoute,
  useRouter,
  useContext,
  computed,
} from "@nuxtjs/composition-api";
import { debounce } from "lodash-es";
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";
import SvgImage from "~/components/General/SvgImage.vue";
import { useProduct } from "~/modules/catalog/product/composables/useProduct";
import { Products } from "~/modules/GraphQL/types";
import { useCategorySearch } from "~/modules/catalog/category/composables/useCategorySearch";
import { useBrandsStore } from "~/stores/brands";
export default defineComponent({
  name: "SearchBar",
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: [
    "set-is-open",
    "set-search-results",
    "set-category-results",
    "set-brands-results",
    "searchValue",
  ],
  setup(props, { emit }) {
    const router = useRouter();
    const { app } = useContext();
    const term = ref("");
    const route = useRoute();
    const brandsStore = useBrandsStore();

    const itemsPerPage = computed(() => {
      if (process.browser) {
        return JSON.parse(localStorage.getItem("items-per-page")) ?? 20;
      } else {
        return 20;
      }
    });

    const { getProductList } = useProduct();

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit("set-is-open", true);
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit("set-is-open", false);
        emit("set-search-results", null);
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector(".search");
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = "";
        }
      } else {
        hideSearch();
        term.value = "";
      }
    };

    const categories = useCategorySearch();

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      categories.search({
        filters: {
          name: {
            match: term.value,
          },
        },
      });
      // M2-579
      const productList: Products = ((await getProductList({
        pageSize: 10,
        search: term.value,
      })) as unknown) as Products;

      let brands = brandsStore.filterForSearch(term.value);

      emit("set-search-results", productList!.items);
      emit("set-category-results", categories.result);
      emit("set-brands-results", brands);
      emit("set-total-results", productList?.total_count);
      emit(
        "searchValue",
        `/search?term=${searchTerm}&itemsPerPage=${itemsPerPage.value}`
      );
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 300);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      router.replace(
        app.localePath(`/search?term=${searchTerm}&itemsPerPage=${itemsPerPage.value}`)
      );
    };

    watch(route, () => {
      hideSearch();
      term.value = "";
    });

    watch(term, (newValue) => {
      if (newValue.length >= 3) {
        showSearch();
      }
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
    };
  },
});
